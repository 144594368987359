import React, { useState, useEffect, useRef, useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard, selectDashboard } from '@pages/Dashboard/slice'

import InputAdornment from '@material-ui/core/InputAdornment'
import InputBase from '@material-ui/core/InputBase'
import SearchResults from '@components/molecules/Search/SearchResults'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { Backdrop } from '@material-ui/core'

import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  Search: {
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down(960)]: {
      width: '100%',
    },
  },
  Search_InputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  Search_Input: {
    backgroundColor: theme.palette.primary.white,
    margin: '8px 0 auto 10px',
    borderRadius: '6px',
    height: '39px',
    width: '221px',
    color: theme.palette.primary.main,
    paddingLeft: '14px',
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down(960)]: {
      margin: '0 0 0 10px',
    },
    [theme.breakpoints.down(500)]: {
      width: '100%',
      margin: '0 16px 0 10px',
    },
  },
  Search_Icon: {
    color: theme.palette.secondary.gray,
  },
  Search_Results: {
    position: 'absolute',
    top: 48,
    left: 10,
    backgroundColor: theme.palette.primary.white,
    maxHeight: '500px',
    overflowY: 'auto',
    [theme.breakpoints.down(960)]: {
      width: 'calc(100%)',
      position: 'relative',
      top: 0,
      left: 0,
      margin: '16px 0px 0px 0px',
      maxHeight: 'none',
      borderRadius: '24px 24px 0px 0px',
      minHeight: 'calc(100vh - 144px)',
      padding: '16px',
    },
  },
  Search_Backdrop: {
    zIndex: 1,
  },
}))

const collectSearchItems = coursesRaw => {
  const courses = []
  const lessons = []

  if (coursesRaw) {
    courses.push(
      ...coursesRaw.reduce((accCourses, item) => {
        accCourses.push({
          id: item?.id,
          title: item?.title,
          isCourse: true,
          iconSrc: item?.icon,
          courseName: item?.title,
        })
        if (item.lessons && item['lessons'].length) {
          lessons.push(
            ...item.lessons.reduce((accLessons, lesson) => {
              accLessons.push({
                id: lesson?.id,
                title: lesson?.title,
                isCourse: false,
                idCourse: item?.id,
                iconSrc: '',
                courseName: item?.title,
              })
              return accLessons
            }, [])
          )
        }
        return accCourses
      }, [])
    )
  }
  return [...courses, ...lessons]
}

const SearchInput = ({ isMobile }) => {
  const c = useStyles()
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const timer = useRef(null)
  const dashboard = useSelector(selectDashboard)
  const data = useMemo(() => collectSearchItems(dashboard.courses || []), [dashboard])

  const [isBackdrop, setIsBackdrop] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredSearchItems, setFilteredSearchItems] = useState([])

  useEffect(() => {
    const { pathname } = history?.location
    if (pathname !== '/dashboard' && pathname !== '/' && !isMobile) {
      dispatch(getDashboard())
    }
  }, [dispatch, history, isMobile])

  const filterSearchItems = filter => {
    const lowercasedFilter = filter?.toLowerCase()

    setFilteredSearchItems(
      data.filter(item => item?.title?.toLowerCase()?.includes(lowercasedFilter))
    )
  }

  const handleSearchOnChange = event => {
    const { value } = event.target

    setSearchValue(value)
    clearTimeout(timer.current)
    timer.current = null

    if (value.length <= 2) {
      setFilteredSearchItems([])
    }

    timer.current = setTimeout(() => {
      timer.current = null

      if (value.length > 2) {
        filterSearchItems(value)
      }

      if (value.length > 2 && !isMobile) {
        setIsBackdrop(true)
      } else {
        setIsBackdrop(false)
      }
    }, 400)
  }

  const handleClickAway = () => {
    if (!isMobile) {
      setSearchValue('')
      setIsBackdrop(false)
      clearTimeout(timer.current)
    }
  }

  const getSpecialMessage = () => {
    if (!dashboard.courses) {
      return <CircularProgress />
    }

    if (searchValue.length === 0) {
      return intl.formatMessage({ id: 'shared.startTypingForResults' })
    }

    if (searchValue.length < 3) {
      return intl.formatMessage({ id: 'shared.continueTypingForResults' })
    }

    if (searchValue.length >= 3 && filteredSearchItems.length === 0 && !timer.current) {
      return intl.formatMessage({ id: 'shared.nothingFound' })
    }

    if (timer.current) {
      return <CircularProgress />
    }

    return null
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={c.Search}>
          <div className={c.Search_InputWrapper}>
            {isMobile && (
              <IconButton onClick={history.goBack}>
                <ArrowBack className={c.Search_Icon} />
              </IconButton>
            )}
            <InputBase
              className={c.Search_Input}
              value={searchValue}
              placeholder={intl.formatMessage({ id: 'shared.search' })}
              onChange={handleSearchOnChange}
              disabled={!dashboard.courses}
              startAdornment={
                <InputAdornment position='start' color='inherit'>
                  <SearchIcon className={c.Search_Icon} />
                </InputAdornment>
              }
            />
          </div>
          {((searchValue.length > 2 && !!data.length) || isMobile) && (
            <div className={c.Search_Results}>
              <SearchResults
                onBackdropSet={setIsBackdrop}
                onSearchValueSet={setSearchValue}
                filteredSearchItems={filteredSearchItems}
                specialMessage={getSpecialMessage()}
                isMobile={isMobile}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Backdrop className={c.Search_Backdrop} open={isBackdrop} />
    </>
  )
}

export default SearchInput
