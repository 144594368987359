import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboard,
  selectDashboard,
  selectDashboardStatus,
  getDashboardSilent,
} from './slice'

import CardCourses from '@components/molecules/CardCourses'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { FormattedMessage, useIntl } from 'react-intl'
import CardWithImage from '@components/molecules/СardWithImage'
import { STATUSES } from '@constants/slices'

const myStyles = makeStyles(theme => ({
  DashBoard: {
    padding: `0px ${theme.spacing(6)}px`,
    [theme.breakpoints.down(600)]: {
      padding: `0px ${theme.spacing(2)}px`,
      flexDirection: 'column',
    },
  },
  DashBoard_Header: {
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
    },
  },
  DashBoard_CoursesWrapper: {
    paddingRight: '16px',
    [theme.breakpoints.down(960)]: {
      paddingRight: '0',
    },
  },
  Card_Paper__blank: {
    padding: 15,
    backgroundColor: '#fff',
    height: 200,
    marginBottom: theme.spacing(2),
  },
  DashBoard_MainTitle: {
    fontSize: '48px',
    lineHeight: '63px',
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      fontSize: '32px',
      padding: `${theme.spacing(1)}px 0`,
      lineHeight: '48px',
    },
  },
  DashBoard_SubTitle: {
    fontSize: '19px',
    lineHeight: '26px',
    padding: 0,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '24px',
      padding: `${theme.spacing(1)}px 0`,
    },
  },
}))

const textForLastTest = dashboard => {
  if (dashboard.last_test) {
    const { surroundingCourse, surroundingLesson, surroundingTopic } = dashboard.last_test
    return [surroundingCourse, surroundingLesson, surroundingTopic]
      .map(item => item?.title)
      .filter(item => !!item)
      .join(' - ')
  }
  return ''
}

const textForLastTopic = dashboard => {
  if (dashboard.last_topic) {
    const { surroundingCourse, surroundingLesson } = dashboard.last_topic

    return [surroundingCourse, surroundingLesson]
      .map(item => item?.title)
      .filter(item => !!item)
      .join(' - ')
  }
  return ''
}

const DashBoard = () => {
  const dispatch = useDispatch()
  const c = myStyles()
  const intl = useIntl()
  const dashboard = useSelector(selectDashboard)
  const dashboardStatus = useSelector(selectDashboardStatus)

  useEffect(() => {
    if (dashboardStatus === STATUSES.LOADED) {
      dispatch(getDashboardSilent())
    } else {
      dispatch(getDashboard())
    }
  }, [dispatch])

  const { last_test, last_topic } = dashboard

  return (
    <Box className={c.DashBoard}>
      <Grid
        item
        container
        direction='column'
        justify='flex-start'
        alignItems='flex-start'
        className={c.DashBoard_Header}
      >
        <Grid md={12} xs={12} item>
          <Typography variant='h3' className={c.DashBoard_MainTitle}>
            <FormattedMessage id='shared.welcome' />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item container md={12} xs={12} direction='row'>
          <Grid item md={12} xs={12} className={c.DashBoard_CoursesWrapper}>
            <CardCourses data={dashboard} status={dashboardStatus} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashBoard
