import React from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import CardActionArea from '@material-ui/core/CardActionArea'
import Box from '@material-ui/core/Box'
import Avatar from 'react-avatar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose'
import Tooltip from '@material-ui/core/Tooltip'

import BorderLinearProgress from '@components/atoms/BorderLinearProgress'

import { FormattedMessage, useIntl } from 'react-intl'

import clsx from 'clsx'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  BlockWithIcon: {
    margin: '10px 16px',
  },
  BlockWithIcon_Icon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  BlockWithIcon_Title: {
    fontSize: '21px',
    lineHeight: '28px',
    height: '28px',
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  BlockWithIcon_Text: {
    fontSize: '12px',
    lineHeight: '16px',
    height: '32px',
    opacity: '0.6',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  BlockWithIcon_AvatarWrapper: {
    width: '114px',
    height: '114px',
    fontSize: '40px',
    flexShrink: '0',
    padding: 0,
    [theme.breakpoints.down(550)]: {
      width: '80px',
      height: '80px',
      fontSize: '20px',
      padding: 0,
    },
  },
  BlockWithIcon_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  BlockWithIcon_ButtonContainer: {
    width: '100%',
    textAlign: 'left',
    gap: theme.spacing(2),
    [theme.breakpoints.down(400)]: {
      gap: 0,
    },
  },
  BlockWithIcon_Button: {
    paddingRight: 0,
    fontSize: '17px',
    lineHeight: '23px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  BlockWithIcon_LinearProgress: {
    marginBottom: '8px',
  },
  BlockWithIcon_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BlockWithIcon_IconContainer: {
    width: '100%',
  },
  BlockWithIcon_LinesEllipsis: {
    wordBreak: 'break-all',
  },
  BlockWithIcon_withoutTooltip: {},
  BlockWithIcon_AvatarAction: {
    height: '100%',
    fontSize: 'inherit',
  },
  BlockWithIcon_ContentContainer: {
    padding: `0px ${theme.spacing(1)}px`,
  },
}))

export default function BlockWithIcon({
  icon = '',
  title = '',
  progress = 0,
  text = '',
  id,
  enrollmentScore,
  resetHandler,
}) {
  const c = useStyles()
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  return (
    <>
      <Box className={c.BlockWithIcon}>
        <Grid container spacing={2} wrap='nowrap' alignItems='center'>
          <Grid item className={c.BlockWithIcon_AvatarWrapper}>
            <CardActionArea
              onClick={() => {
                setTimeout(() => {
                  history.push(`/course/${id}`)
                }, 700)
              }}
              className={c.BlockWithIcon_AvatarAction}
            >
              <Avatar
                className={c.BlockWithIcon_Icon}
                src={icon}
                color={theme.palette.secondary.main}
                size='100%'
                round={true}
                title={title}
                name={title.replace(/[^a-zA-Z\s]+/g, '')}
                maxInitials={2}
              />
            </CardActionArea>
          </Grid>
          <Grid item className={c.BlockWithIcon_IconContainer}>
            <CardActionArea
              onClick={() => {
                setTimeout(() => {
                  history.push(`/course/${id}`)
                }, 700)
              }}
              className={c.BlockWithIcon_ContentContainer}
            >
              <div>
                <Typography className={clsx(c.BlockWithIcon_Title)} component='div'>
                  <LinesEllipsisLoose
                    text={title}
                    maxLine='1'
                    lineHeight='28'
                    className={c.BlockWithIcon_LinesEllipsis}
                  />
                </Typography>
              </div>
              <Tooltip title={text} classes={{ tooltip: c.BlockWithIcon_TextTooltip }}>
                <Typography className={clsx(c.BlockWithIcon_Text)} component='div'>
                  <LinesEllipsisLoose
                    text={text}
                    style={{ wordBreak: 'normal' }}
                    maxLine='2'
                    lineHeight='16'
                    className={c.BlockWithIcon_LinesEllipsis}
                  />
                </Typography>
              </Tooltip>
            </CardActionArea>
            <Grid className={c.BlockWithIcon_ButtonContainer} container item>
              <Button
                onClick={() => {
                  setTimeout(() => {
                    history.push(`/course/${id}`)
                  }, 700)
                }}
              >
                <Typography className={c.BlockWithIcon_Button} color='primary'>
                  <FormattedMessage id='shared.view' />
                </Typography>
              </Button>
              <Button onClick={resetHandler}>
                <Typography className={c.BlockWithIcon_Button} color='primary'>
                  <FormattedMessage id='shared.resetProgress' />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <BorderLinearProgress
        className={c.RightSideNavigationCard_BorderLinearProgress}
        variant='determinate'
        value={progress * 100}
      />
    </>
  )
}
