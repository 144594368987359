import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
    palette: {
        primary: {
            main: '#203956',
            mainTransparent: '#203956',
            white: '#FFFFFF',
            green: '#6FC380',
            variant1: '#203956',
            variant2: '#203956',
            variant3: '#203956',
            variant4: '#203956',
        },
        secondary: {
            main: '#5BA0F2',
            gray: '#707070',
            lightGray: '#C0C0C0',
            variant1: '#FFB600',
            variant2: '#5BA0F2',
            variant3: '#FFA81b',
            variant4: '#EF8122',
        },
        content: {
            accent: '#5BA0F2',
        },
        background: {
            default: '#FFFFFF',
            light: '#fefefe',
            colored: '#5BA0F21a',
        },
        disabled: {
            main: '#707070',
        },
        error: {
            main: '#ba2424',
        },
        shadow: {
            default: '#00000029',
        },
        header: {
            background: 'rgba(255, 255, 255, 0.65)',
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: '#707070',
            },
        },
    },
})