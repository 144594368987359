import React from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import DoubleArrow from '@material-ui/icons/DoubleArrow'
import { useState } from 'react'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  BreadCramps: {},
  BreadCramps_Container: {
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  BreadCramps_Link: {
    padding: 0,
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 500,
    cursor: 'pointer',
    color: 'black',
  },
  BreadCramps_Icon: {
    padding: 0,
    height: '18px',
    color: 'black',
  },
  BreadCramps_First_Entry: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '0px',
  },
  BreadCramps_Second_Entry: {
    marginLeft: '0px',
  },
}))

const BreadCrampsEntry = ({ content, courseId }) => {
  const c = useStyles()
  const history = useHistory()
  const buildURL = content => {
    if (!content) return ''
    return `/${content.type}/${courseId}${
      content.type !== 'course' ? '/' + content.id : ''
    }`
  }
  const url = buildURL(content)
  return (
    <>
      {url !== '' && <DoubleArrow className={c.BreadCramps_Icon} />}
      {url !== '' && (
        <Link className={c.BreadCramps_Link} onClick={() => history.push(url)}>
          {content.title}
        </Link>
      )}
      {content.underlyingContent ? (
        <BreadCrampsEntry
          content={content.underlyingContent}
          courseId={courseId}
        ></BreadCrampsEntry>
      ) : null}
    </>
  )
}

const BreadCramps = ({ content }) => {
  const c = useStyles()
  const history = useHistory()
  const [breadCrampRoot, setBreadCrampRoot] = useState(null)
  const [courseId, setCourseId] = useState(null)

  useEffect(() => {
    let root = JSON.parse(JSON.stringify(content))

    while (root.surroundingContent) {
      root.surroundingContent.underlyingContent = root
      root = root.surroundingContent
    }
    setCourseId(root.id)
    setBreadCrampRoot(root)
  }, [content])

  return (
    <Grid container className={c.BreadCramps_Container}>
      <Link className={c.BreadCramps_Link} onClick={() => history.push('/dashboard')}>
        <FormattedMessage id='shared.dashboard' />
      </Link>
      {breadCrampRoot ? (
        <BreadCrampsEntry content={breadCrampRoot} courseId={courseId}></BreadCrampsEntry>
      ) : null}
    </Grid>
  )
}

export default BreadCramps
