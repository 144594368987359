import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import SideHeader from '@components/molecules/SideHeader'
import DOMPurify from 'dompurify'
import { useIntl } from 'react-intl'
import LayoutWithoutImage from '@components/molecules/LayoutWithoutImage'
import { STATUSES } from '@constants/slices'
import {
  getCourseContent,
  selectCourseTree,
  selectCourseTreeStatus,
  selectCurrentContent,
} from '@services/courseOverview/slice'
import FENIntegration from '@components/atoms/FENIntegration'

const LeftSideTest = ({
  test,
  courseId,
}) => {
  const {  testLink, progress } = test
  const intl = useIntl()

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const sandbox = node.getAttribute('sandbox') || ''
      if (!sandbox) {
        return node.parentNode?.removeChild(node)
      }
    }
  })
  return (
    <>
      <SideHeader
        content={test}
        courseId={courseId}
        type={intl.formatMessage({ id: 'shared.test' })}
        showProgress
      />
      <FENIntegration testLink={testLink} progress={progress}></FENIntegration>
    </>
  )
}

const Test = () => {
  const dispatch = useDispatch()
  const { courseId, testId } = useParams()
  const courseStatus = useSelector(selectCourseTreeStatus)
  const courseTree = useSelector(selectCourseTree)
  const test = useSelector(selectCurrentContent)
  const testStatus = test && testId == test.id ? STATUSES.LOADED : STATUSES.LOADING

  useEffect(() => {
    if (testStatus === STATUSES.LOADING) {
      dispatch(getCourseContent(courseId, testId))
    }
  }, [dispatch, courseId, testId, testStatus])

  return (
    <LayoutWithoutImage
      status={courseStatus}
      courseTree={courseTree}
      currentContent={test}
    >
      <LeftSideTest
        test={test}
        courseId={courseId}
      />
    </LayoutWithoutImage>
  )
}

export default Test
