import React, { useEffect } from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ProfileMenu from '@components/molecules/ProfileMenu'
import ProfileBioInfo from '@components/molecules/ProfileBioInfo'
import Grid from '@material-ui/core/Grid'
import Spacer from '@components/atoms/Spacer'
import { getProfile, selectProfile } from '@pages/Profile/slice'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(theme => {
  return {
    Profile: {
      paddingTop: 48,
      height: 'auto',
    },

    Profile_Row: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(960)]: {
        flexDirection: 'column',
      },
    },

    Profile_LeftSidePaper: {
      backgroundColor: theme.palette.secondary.variant1,
    },

    Profile_LoadingContainer: {
      height: '100vh',
    },

    LeftSide: {
      width: '452px',
      position: 'relative',
      flexShrink: '0',
      [theme.breakpoints.down(1600)]: {
        width: '200px',
      },
      [theme.breakpoints.down(960)]: {
        width: '100%',
        height: '200px',
      },
      [theme.breakpoints.down(700)]: {
        height: '128px',
      },
    },
    LeftSide_AvatarWrapper: {
      width: '452px',
      height: '452px',
      fontSize: '150px',
      [theme.breakpoints.down(1600)]: {
        width: '200px',
        height: '200px',
        fontSize: '100px',
      },
      [theme.breakpoints.down(700)]: {
        width: '128px',
        height: '128px',
        fontSize: '65px',
      },
    },
    LeftSide_AvatarBG: {
      position: 'absolute',
      top: '226px',
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: -1,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down(1600)]: {
        top: '100px',
      },
      [theme.breakpoints.down(960)]: {
        left: '100px',
        top: '0',
        marginRight: '-32px',
        width: 'calc(100% - 100px)',
      },
      [theme.breakpoints.down(768)]: {
        marginRight: '-16px',
      },
      [theme.breakpoints.down(700)]: {
        left: '64px',
        width: 'calc(100% - 64px)',
      },
    },

    LeftSide_MenuContainer: {
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      paddingTop: 52,
      paddingLeft: 48,
      [theme.breakpoints.down(1600)]: {
        padding: '32px 10px 0 10px',
      },
      [theme.breakpoints.down(960)]: {
        position: 'absolute',
        right: '16px',
        top: '50%',
        display: 'inline-flex',
        width: 'auto',
        padding: '0',
        transform: 'translateY(-50%)',
      },
    },

    RightSide: {
      width: '100%',
    },

    RightSide_TitleContainer: {
      height: '226px',
      display: 'flex',
      alignItems: 'flex-end',
      marginLeft: 77,
      [theme.breakpoints.down(1600)]: {
        height: '100px',
      },
      [theme.breakpoints.down(960)]: {
        height: '100px',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginLeft: '0',
        textAlign: 'right',
      },
      [theme.breakpoints.down(700)]: {
        height: '80px',
      },
    },

    RightSide_Title: {
      [theme.breakpoints.down(1300)]: {
        fontSize: '4rem',
      },
      [theme.breakpoints.down(960)]: {
        fontSize: '3rem',
      },
      [theme.breakpoints.down(700)]: {
        fontSize: '32px',
        lineHeight: '40px',
      },
    },

    RightSide_ContentPaper: {
      paddingTop: 36,
      paddingLeft: 104,
      paddingRight: 24,
      borderRadius: '0 24px 0 0',
      height: 'calc(100% - 226px)',
      boxShadow: 'none',
      maxWidth: '100%',
      [theme.breakpoints.down(1600)]: {
        height: 'calc(100% - 100px)',
      },
      [theme.breakpoints.down(1300)]: {
        paddingLeft: '30px',
      },
      [theme.breakpoints.down(960)]: {
        borderRadius: '24px 24px 0 0',
        margin: '0 0px',
      },
      [theme.breakpoints.down(768)]: {
        margin: '0 0px',
      },
      [theme.breakpoints.down(700)]: {
        padding: '20px 10px',
      },
    },
  }
})

const ProfileLayout = ({ children }) => {
  const dispatch = useDispatch()
  const c = useStyles()
  const theme = useTheme()

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  const { value: profile } = useSelector(selectProfile)
  const name = [profile?.firstName, profile?.lastName].filter(item => !!item).join(' ')

  return (
    <>
      {isEmpty(profile) ? (
        <Grid
          className={c.Profile_LoadingContainer}
          container
          justify='center'
          alignItems='center'
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box className={clsx(c.Profile, c.Profile_Row)}>
          <div className={c.LeftSide}>
            <div className={c.LeftSide_AvatarWrapper}>
              <Avatar
                src={profile?.avatarPath}
                size='100%'
                round={true}
                title={profile?.userName}
                name={name}
                maxInitials={2}
              />
            </div>
            <div className={c.LeftSide_AvatarBG}></div>
            <div className={c.LeftSide_MenuContainer}>
              <ProfileMenu />
            </div>
          </div>
          <div className={c.RightSide}>
            <div className={c.RightSide_TitleContainer}>
              <Typography variant='h1' color='primary' className={c.RightSide_Title}>
                {name}
              </Typography>
            </div>
            <Paper className={c.RightSide_ContentPaper} elevation={2}>
              <ProfileBioInfo />
              <Spacer size='100px' mobileSize='30px' />
              {children}
            </Paper>
          </div>
        </Box>
      )}
    </>
  )
}

export default ProfileLayout
