import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        value: {},
        status: STATUSES.INIT,
    },
    reducers: {
        loaded: (_, { payload }) => ({ value: payload, status: STATUSES.LOADED }),
        loading: state => ({...state, status: STATUSES.LOADING }),
        error: state => ({...state, status: STATUSES.ERROR }),
    },
})

export const getDashboard = () => dispatch => {
    dispatch(loading())
    api({ endpoint: '/user/courses' })
        .then(res => {
            dispatch(loaded(res.data))
        })
        .catch(e => {
            if (!(e.name && e.name === 'CanceledError')) {
                dispatch(error())
            }
        })
}

export const getDashboardSilent = () => (dispatch, getState) => {
    api({ endpoint: '/user/courses' })
        .then(res => {
            dispatch(loaded(res.data))
        })
        .catch(e => {
            if (!(e.name && e.name === 'CanceledError')) {
                dispatch(error())
            }
        })
}

export const selectDashboard = state => state.dashboard.value
export const selectDashboardStatus = state => state.dashboard.status

export const { loaded, loading, error } = dashboardSlice.actions
export default dashboardSlice.reducer