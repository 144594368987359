import React, { useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormattedMessage } from 'react-intl'
import { STATUSES } from '@constants/slices'
import ScrollToTopButton from '@components/atoms/ScrollToTopButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCourseOverviewIsOpen,
  setClose,
  toggleOverview,
} from '@services/courseOverview/slice'
import CourseOverview from '@components/organisms/CourseOverview'
import NavigationCardFooter from './NavigationCardFooter'
import { useEffect } from 'react'
import { getCourseContentById } from '@services/courseCache'
import { useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import DoneButton from '@components/atoms/DoneButton'
import { min } from 'lodash'
import NotepadCard from './NotepadCard'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  LayoutWithImage: {
    minHeight: '100%',
    width: '100%',
  },
  LayoutWithImage_BackgroundImage: {
    position: 'absolute',
    top: 64,
    left: 0,
    right: 0,
    objectFit: 'cover',
    width: '100%',
    height: '430px',
    zIndex: -1,
    [theme.breakpoints.down(768)]: {
      height: '270px',
    },
    [theme.breakpoints.down(600)]: {
      height: '200px',
    },
  },
  LayoutWithImage_MainWrapper: {
    width: '100%',
    minHeight: '100%',
  },
  LayoutWithImage_InformationCard: {
    marginTop: '32px',
    height: '100%',
    borderRadius: '24px 24px 0px 0px',
    padding: theme.spacing(6),
    [theme.breakpoints.down(768)]: {
      marginTop: '278px',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '180px',
      padding: theme.spacing(2),
    },
  },
  LayoutWithImage_ContentWrapper: {
    padding: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(2),
    },
    zIndex: 10,
  },
  LayoutWithImage_StatusContainer: {
    height: '100vh',
  },
  LayoutWithImage_Background: {
    backgroundColor: theme.palette.background.colored,
    position: 'absolute',
    height: 'min(700px, 66%)',
    width: '100%',
    top: 64,
    overflowX: 'hidden',
    zIndex: -1,
  },
  LayoutWithImage_FullWitdh: {
    width: '100%',
  },
  LayoutWithoutImage_MobileCourseOverviewC: {
    position: 'relative',
    left: 0,
    maxWidth: '100%',
  },
  LayoutWithImage_Hidden: {
    display: 'none',
  },
}))

const LayoutWithoutImage = ({ children, currentContent, courseTree, status }) => {
  const c = useStyles()
  const dispatch = useDispatch()
  const isOpen = useSelector(selectCourseOverviewIsOpen)
  const [enrichedContent, setEnrichedContent] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const history = useHistory()

  useEffect(() => {
    if (status === STATUSES.LOADED && currentContent) {
      const enriched = getCourseContentById(courseTree, currentContent.id)
      setEnrichedContent(enriched)
    }
  }, [status, currentContent])

  const notepadAction = () => {
    const { id, title } = courseTree
    history.push(`/notepad/${id}`, {
      courseId: id,
      courseTitle: title,
    })
  }

  return (
    <>
      {status === STATUSES.LOADING && (
        <Grid
          className={c.LayoutWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <CircularProgress />
        </Grid>
      )}
      {status === STATUSES.ERROR && (
        <Grid
          className={c.LayoutWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <Typography component='p'>
            <FormattedMessage id='shared.somethingWrong' />
          </Typography>
        </Grid>
      )}
      {status === STATUSES.LOADED && (
        <Grid
          container
          justify='center'
          alignItems='stretch'
          className={c.LayoutWithImage}
        >
          <Grid item className={c.LayoutWithImage_MainWrapper}>
            <Grid
              container
              className={c.LayoutWithImage_ContentWrapper}
              direction='column'
            >
              {isOpen && isMobile && courseTree && (
                <Grid className={c.LayoutWithoutImage_MobileCourseOverviewC}>
                  <CourseOverview
                    courseTree={courseTree}
                    currentContent={enrichedContent}
                    isMobileView={true}
                    closeCallback={() => {
                      dispatch(toggleOverview(isOpen))
                    }}
                  ></CourseOverview>
                  {courseTree?.notepad && (
                    <NotepadCard
                      notepad={courseTree?.notepad}
                      handleAction={notepadAction}
                      isMobileView={true}
                    ></NotepadCard>
                  )}
                </Grid>
              )}
              {enrichedContent && (
                <>
                  <Grid
                    item
                    container
                    direction='row'
                    justify='center'
                    alignItems='stretch'
                  >
                    {isOpen && !isMobile && courseTree && (
                      <Grid item md={4} sm={12}>
                        <CourseOverview
                          courseTree={courseTree}
                          currentContent={enrichedContent}
                          closeCallback={() => {
                            dispatch(toggleOverview(isOpen))
                          }}
                        ></CourseOverview>
                        {courseTree?.notepad && (
                          <NotepadCard
                            notepad={courseTree?.notepad}
                            handleAction={notepadAction}
                          ></NotepadCard>
                        )}
                      </Grid>
                    )}
                    <Grid
                      item
                      md={isOpen ? 8 : 10}
                      className={clsx(
                        c.LayoutWithImage_FullWitdh,
                        isOpen && isMobile && courseTree ? c.LayoutWithImage_Hidden : null
                      )}
                    >
                      {children}
                    </Grid>
                  </Grid>
                  {currentContent.type !== 'notepad' && (
                    <NavigationCardFooter
                      content={courseTree}
                      currentContent={enrichedContent}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <ScrollToTopButton></ScrollToTopButton>
        </Grid>
      )}
    </>
  )
}

export default LayoutWithoutImage
