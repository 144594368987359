import React, { useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AppContext from './context/index'

import Community from '@pages/Сommunity'
import Dashboard from '@pages/Dashboard'
import MobileSearch from '@pages/MobileSearch'
import Course from '@pages/Course'
import Lesson from '@pages/Lesson'
import Test from '@pages/Test'
import Topic from '@pages/Topic'
import Impressum from '@pages/Impressum'

import Header from '@components/molecules/Header'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import theme from './theme'
import ProfileLayout from '@components/organisms/ProfileLayout'
import Settings from '@pages/Profile/Settings'
import Stats from '@pages/Profile/Stats'
import Login from '@pages/Login'
import { getCurrentUser } from '@services/currentUser'
import ReactGA from 'react-ga4'
import Notepad from '../@pages/Notepad'
import ScrollToTop from '../@components/atoms/ScrollToTop'
import Footer from '@components/molecules/Footer'
import { initChat } from '@services/chatIntegration'

const useStyles = makeStyles(theme => ({
  Content: {
    minHeight: '100%',
  },
}))

const PrivateRoute = ({ children, ...props }) => {
  const currentUser = getCurrentUser()

  return (
    <Route
      {...props}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const initGA = () => {
  ReactGA.initialize('G-S00JQXS478')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const App = () => {
  const c = useStyles()
  const { appState, setAppState } = useContext(AppContext)
  const currentUser = getCurrentUser()

  useEffect(() => {
    if (currentUser) {
      //initGA()
      initChat()
    }
  }, [currentUser])

  useEffect(() => {
    appState.isReady = true
    setAppState(appState)
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main className={c.Content}>
        <ScrollToTop>
          <Switch>
            <Route exact path='/login'>
              <Login />
            </Route>
            <Route exact path='/impressum'>
              <Impressum />
            </Route>
            <PrivateRoute exact path='/'>
              <Dashboard />
            </PrivateRoute>
            <Redirect from='/dashboard' to='/' />
            <PrivateRoute exact path='/community'>
              <Community />
            </PrivateRoute>
            <PrivateRoute exact path='/search'>
              <MobileSearch />
            </PrivateRoute>
            <PrivateRoute exact path='/course/:courseId'>
              <Course />
            </PrivateRoute>
            <PrivateRoute exact path='/lesson/:courseId/:lessonId'>
              <Lesson />
            </PrivateRoute>
            <PrivateRoute exact path='/test/:courseId/:testId'>
              <Test />
            </PrivateRoute>
            <PrivateRoute exact path='/topic/:courseId/:topicId'>
              <Topic />
            </PrivateRoute>
            <PrivateRoute exact path='/notepad/:courseId'>
              <Notepad />
            </PrivateRoute>
            <ProfileLayout>
              <Switch>
                <PrivateRoute exact path='/profile'>
                  <Settings />
                </PrivateRoute>
                <PrivateRoute exact path='/statistics'>
                  <Stats />
                </PrivateRoute>
                <Redirect from='*' to='/dashboard' />
              </Switch>
            </ProfileLayout>
          </Switch>
        </ScrollToTop>
      </main>
      <Footer></Footer>
    </ThemeProvider>
  )
}
export default App
