import { CardActionArea, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import MenuOpenIcon from '@material-ui/icons/MenuOpenRounded'
import clsx from 'clsx'

import { FormattedMessage } from 'react-intl'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  CourseOverviewButton_Maincontainer: {
    marginBottom: theme.spacing(2),
  },
  CourseOverviewButton_IconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100%)',
  },
  CourseOverviewButton_Header: {
    backgroundColor: theme.palette.primary.variant2,
    boxShadow: 'none',
  },
  CourseOverviewButton_Text: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 20,
    paddingLeft: '8px',
  },
  CourseOverviewButton_Icon: {
    color: 'white',
    fontSize: 24,
  },
  CourseOverviewButton_Icon__Hover: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
  },
  CourseOverviewButton_Base: {
    width: '40px',
    height: '40px',
  },
  CourseOverviewButton_Hover: {
    marginLeft: '-32px',
    marginTop: '-6px',
    width: '150px',
    height: '46px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    [theme.breakpoints.down(600)]: {
      marginLeft: '-16px',
    },
  },
}))

const CourseOverviewButton = ({ callBack }) => {
  const c = useStyles()
  const [isHover, setIsHover] = useState(false)

  return (
    <CardActionArea
      className={clsx(
        c.CourseOverviewButton_Maincontainer,
        c.CourseOverviewButton_Header,
        isHover ? c.CourseOverviewButton_Hover : c.CourseOverviewButton_Base
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={callBack}
    >
      <Paper
        square
        className={clsx(
          c.CourseOverviewButton_Header,
          c.CourseOverviewButton_IconContainer
        )}
      >
        <MenuOpenIcon
          className={clsx(
            c.CourseOverviewButton_Icon,
            isHover ? c.CourseOverviewButton_Icon__Hover : null
          )}
        ></MenuOpenIcon>
        {isHover ? (
          <Typography className={c.CourseOverviewButton_Text}>
            <FormattedMessage id={'shared.courseoverview'}></FormattedMessage>
          </Typography>
        ) : (
          <></>
        )}
      </Paper>
    </CardActionArea>
  )
}

export default CourseOverviewButton
