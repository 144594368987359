import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { updateCourseTree } from '@services/courseOverview/slice'
import IframeResizer from 'iframe-resizer-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  Test_Iframe: {
    minWidth: '100%',
    border: `5px solid ${theme.palette.content.accent}`,
  },
  Test_Iframe__Loading: {
    display: 'none',
  },
  Test_IframeC: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  Test_LoadingFrame: {
    border: `5px solid ${theme.palette.content.accent}`,
    backgroundColor: 'white',
    minHeight: '100px',
  },
}))

const FENIntegration = ({ testLink, progress = null }) => {
  const dispatch = useDispatch()
  const c = useStyles()
  const [updateNeeded, setUpdateNeeded] = useState(false)
  const updatedNeededRef = React.useRef(updateNeeded)
  const [isFenLoaded, setIsFenLoaded] = useState(false)

  useEffect(() => {
    let fallbackTimeout
    setIsFenLoaded(false)
    fallbackTimeout = setTimeout(() => setIsFenLoaded(true), 10000)
    return () => {
      if (fallbackTimeout) {
        clearTimeout(fallbackTimeout)
      }
    }
  }, [testLink])

  const onMessage = data => {
    if (data.message.includes('FEN_LOADED') || data.message.includes('RT_LOADED')) {
      setIsFenLoaded(true)
    } else if (data.message.includes('FEN_UPDATE_TRIGGERED')) {
      if (data.message.includes('--force')) {
        if (progress && progress !== 1) {
          setUpdateNeeded(false)
          setTimeout(() => dispatch(updateCourseTree()), 500)
        }
      } else {
        setUpdateNeeded(true)
      }
    }
  }

  useEffect(() => {
    updatedNeededRef.current = updateNeeded
  }, [updateNeeded])

  useEffect(() => {
    return () => {
      if (updatedNeededRef.current) {
        dispatch(updateCourseTree(true))
      }
    }
  }, [testLink])

  return (
    <div className={c.Test_IframeC}>
      <IframeResizer
        heightCalculationMethod='taggedElement'
        src={`${testLink}`}
        className={isFenLoaded ? c.Test_Iframe : c.Test_Iframe__Loading}
        onMessage={onMessage}
      />
      {!isFenLoaded && (
        <Grid
          container
          justify='center'
          alignItems='center'
          className={c.Test_LoadingFrame}
        >
          <CircularProgress />
        </Grid>
      )}
    </div>
  )
}

export default FENIntegration
