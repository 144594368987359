import { getCurrentUser } from './currentUser'
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl'

import locale_en from '@translations/en.json'
import locale_de from '@translations/de.json'

const DEFAULT_LOCALE = 'de'

const data = {
    de: locale_de,
    en: locale_en,
}

export const lang = navigator.language || navigator.languages

const getLocale = () => {
    if (lang in data) return lang
    else if (lang.substring(0, 2) in data) return lang.substring(0, 2)
    else return DEFAULT_LOCALE
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

const intl = createIntl({
        locale: getLocale(),
        messages: data[getLocale()],
    },
    cache
)

const initFreshChat = () => {
    window.fcWidget.init({
        token: 'c4d92374-bb66-43c9-931a-26f92b2c6f45',
        host: 'https://wchat.freshchat.com',
        config: {
            disableEvents: true,
            cssNames: {
                widget: 'fc_frame',
                open: 'fc_open',
                expanded: 'fc_expanded',
            },
            showFAQOnOpen: true,
            hideFAQ: true,
            agent: {
                hideName: false,
                hidePic: false,
                hideBio: false,
            },
            headerProperty: {
                //If you have multiple sites you can use the appName and appLogo to overwrite the values.
                appName: 'Plakos Chat',
                appLogo: 'https://d1qb2nb5cznatu.cloudfront.net/startups/i/2473-2c38490d8e4c91660d86ff54ba5391ea-medium_jpg.jpg?buster=1518574527',
                backgroundColor: '#5BA0F2',
                foregroundColor: '#333333',
                backgroundImage: 'https://wchat.freshchat.com/assets/images/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png',
            },
            content: {
                placeholders: {
                    reply_field: intl.formatMessage({ id: 'chat.reply' }),
                },
                actions: {
                    push_notify_yes: intl.formatMessage({ id: 'shared.yes' }),
                    push_notify_no: intl.formatMessage({ id: 'shared.no' }),
                },
                headers: {
                    chat: intl.formatMessage({ id: 'chat.header' }),
                    chat_help: intl.formatMessage({ id: 'chat.help' }),
                    push_notification: intl.formatMessage({ id: 'chat.pushInfo' }),
                    channel_response: {
                        offline: intl.formatMessage({ id: 'chat.offline' }),
                        online: {
                            minutes: {
                                one: intl.formatMessage({ id: 'chat.onlineMinutesOne' }),
                                more: intl.formatMessage({ id: 'chat.onlineMinutesTwo' }),
                            },
                            hours: {
                                one: intl.formatMessage({ id: 'chat.onlineHoursOne' }),
                                more: intl.formatMessage({ id: 'chat.onlineHoursTwo' }),
                            },
                        },
                    },
                },
            },
        },
    })

    const userData = getCurrentUser()

    // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

    // To set unique user id in your system when it is available

    // To set user name
    window.fcWidget.user.setFirstName(userData.name)

    // To set user email
    window.fcWidget.user.setEmail(userData.email)

    // To set user properties
    window.fcWidget.user.setProperties({
        plan: 'Pro', // meta property 1
        status: 'Active', // meta property 2
    })
}
const initialize = (i, t) => {
    var e
    i.getElementById(t) ?
        initFreshChat() :
        (((e = i.createElement('script')).id = t),
            (e.async = !0),
            (e.src = 'https://wchat.freshchat.com/js/widget.js'),
            (e.onload = initFreshChat),
            i.head.appendChild(e))
}

const initiateCall = () => {
    initialize(document, 'Freshdesk Messaging-js-sdk')
}

export const initChat = () => {
    initiateCall()
}